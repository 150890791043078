import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Notification,
  NotificationTitle,
  NotificationBody,
  NotificationFooter,
} from '@blendle/lego';
import { translate } from 'instances/i18n';
import formatCurrency from 'helpers/formatCurrency';

function RefundSuccessNotification({ onClose, price }) {
  return (
    <Notification data-testid="notification-refund-success">
      <NotificationTitle>{translate('refund.successTitle')}</NotificationTitle>
      <NotificationBody>
        {translate('refund.success', [formatCurrency(price, { amountIsInCents: true })])}
      </NotificationBody>
      <NotificationFooter>
        <Button onClick={onClose} size="small" color="razzmatazz">
          {translate('refund.successCta')}
        </Button>
      </NotificationFooter>
    </Notification>
  );
}

RefundSuccessNotification.propTypes = {
  price: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RefundSuccessNotification;
