import React, { PureComponent } from 'react';
import AltContainer from 'alt-container';
import withRouter from 'react-router/lib/withRouter';
import { Dialog, DialogBody } from '@blendle/lego';
import { once } from 'lodash';
import { STATUS_OK, STATUS_ERROR, INTERNAL_LOCATION } from 'app-constants';
import Analytics from 'instances/analytics';
import Settings from 'controllers/settings';
import RefundActions from 'actions/RefundActions';
import NotificationsActions from 'actions/NotificationsActions';
import ItemStore from 'stores/ItemStore';
import TilesStore from 'stores/TilesStore';
import RefundStore from 'stores/RefundStore';
import { getItemFromTiles } from 'selectors/tiles';
import RefundSuccessNotification from 'components/notifications/RefundSuccessNotification';
import { refund } from '../helpers/refund';
import RefundReasons from '../components/RefundReasons';

class RefundContainer extends PureComponent {
  componentDidMount() {
    RefundActions.resetState.defer();
    RefundActions.setPossibleReasons.defer();

    const { hasPrinted, hasCopiedAll, selectedItemId } = ItemStore.getState();

    if (hasPrinted) {
      RefundActions.setReason.defer('printed');
    } else if (hasCopiedAll) {
      RefundActions.setReason.defer('copied');
    }
    Analytics.track('Refund Dialogue Open', {
      item_id: selectedItemId,
      internal_location: INTERNAL_LOCATION.ITEM,
    });

    RefundStore.listen(this._shouldCallOnRefundSuccess);
  }

  componentWillUnmount() {
    RefundActions.resetState.defer();
    RefundStore.unlisten(this._shouldCallOnRefundSuccess);
  }

  _openRefundNotice = (itemId, price) => {
    const RefundSuccessProps = {
      price,
      onClose: () => NotificationsActions.hideNotification(`refund-${itemId}`),
    };

    this._notificationTimeout = setTimeout(() => {
      NotificationsActions.showNotification(
        RefundSuccessNotification,
        RefundSuccessProps,
        `refund-${itemId}`,
        { duration: 10000 },
      );
    }, 1000);
  };

  _shouldCallOnRefundSuccess = ({ status }) => {
    if (status === STATUS_OK) {
      this._onRefundSuccess();
    }
  };

  sendRefundDialogCloseEvent = () => {
    const { selectedItemId } = ItemStore.getState();

    Analytics.track('Refund Dialogue Close', {
      item_id: selectedItemId,
      internal_location: INTERNAL_LOCATION.ITEM,
    });
  };

  _onRefundSuccess = once(() => {
    // will only be called once per instance
    RefundActions.resetState.defer();

    const { selectedItemId: itemId, returnUrl } = ItemStore.getState();

    this.sendRefundDialogCloseEvent();

    if (Settings.embedded) {
      window.parent.postMessage({ event: 'blendle.frame.close' }, Settings.embeddedOrigin);
    }

    const tile = getItemFromTiles(itemId, TilesStore.getState());

    this._openRefundNotice(itemId, tile.price);
    /**
     *  Navigate away from this url. If the same is opened from a widget
     *  again, it will be correctly reloaded. SetTimeout to prevent
     *  a dispatch in dispatch error.
     */
    setTimeout(() => {
      this.props.router.push(returnUrl);
    });
  });

  _onDismiss = () => {
    this.sendRefundDialogCloseEvent();

    this.props.router.goBack();
  };

  _onSetMessage(ev) {
    ev.preventDefault();

    RefundActions.setMessage(ev.target.value);
  }

  // eslint-disable-next-line react/prop-types
  _renderRefundDialog = ({ itemState, tilesState, refundState }) => {
    const tile = getItemFromTiles(itemState.selectedItemId, tilesState);
    if (!tile) {
      return null;
    }
    const { price } = tile;
    const { reasons, reason, message, status, error } = refundState;

    return (
      <Dialog
        open
        hideClose
        onClose={this._onClose}
        data-testid="refund-dialog"
        style={{ width: status === STATUS_ERROR ? 360 : 420 }}
      >
        <DialogBody>
          <RefundReasons
            price={price / 100}
            reasons={reasons}
            selectedReason={reason}
            message={message}
            onClose={this._onClose}
            onDismiss={this._onDismiss}
            onSelectReason={RefundActions.setReason}
            onSetMessage={this._onSetMessage}
            onRefund={refund}
            status={status}
            error={error}
          />
        </DialogBody>
      </Dialog>
    );
  };

  render() {
    return (
      <AltContainer
        render={this._renderRefundDialog}
        stores={{
          itemState: ItemStore,
          tilesState: TilesStore,
          refundState: RefundStore,
        }}
      />
    );
  }
}

export default withRouter(RefundContainer);
