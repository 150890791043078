import React, { memo } from 'react';
import { number, arrayOf, string, func } from 'prop-types';
import { Button, TextArea } from '@blendle/lego';
import { STATUS_PENDING, STATUS_ERROR, STATUS_INITIAL } from 'app-constants';
import { translate, translateElement } from 'instances/i18n';
import formatCurrency from 'helpers/formatCurrency';
import styledModule from 'helpers/styledModule';
import useHasCreditsCapability from 'hooks/useHasCreditsCapability';
import CSS from './styles.scss';

const RefundDialog = styledModule.div(CSS.refundDialog);
const Title = styledModule.h2(CSS.title);
const Paragraph = styledModule.p(CSS.paragraph);
const StyledButton = styledModule(Button)(CSS.button);
const SubmitButton = styledModule(StyledButton)(CSS.submitButton);
const Input = styledModule('input')(CSS.input);
const Label = styledModule('label')(CSS.label);
const OtherReason = styledModule.div(CSS.otherReason);
const OtherTextArea = styledModule(TextArea)(CSS.otherTextArea);

const RefundReasons = memo(
  ({
    price,
    reasons,
    selectedReason,
    message,
    onDismiss,
    onSelectReason,
    onSetMessage,
    onRefund,
    status,
    error,
  }) => {
    const hasCreditsCapability = useHasCreditsCapability();

    if (status === STATUS_ERROR) {
      const isRefundLimitError = error === 'refund_account_limit';

      const creditsRefundErrorMessage = isRefundLimitError
        ? translateElement('refund.account_limit_dialogue_credits.message')
        : translate('refund.not_refundable_credits');

      const micropaymentRefundErrorMessage = isRefundLimitError
        ? translateElement('refund.account_limit_dialogue.message')
        : translate('refund.not_refundable');

      return (
        <RefundDialog data-testid="refund-error">
          <Title>
            {isRefundLimitError
              ? translate('refund.account_limit_dialogue.title')
              : translate('app.error.title')}
          </Title>
          <Paragraph>
            {hasCreditsCapability ? creditsRefundErrorMessage : micropaymentRefundErrorMessage}
          </Paragraph>
          <StyledButton color="razzmatazz" onClick={onDismiss}>
            {translate('app.buttons.i_get_it')}
          </StyledButton>
        </RefundDialog>
      );
    }

    const renderInput = reason => {
      return (
        <Input
          key={`input-reason-${reason}`}
          type="radio"
          name="reason"
          id={`reason-${reason}`}
          value={reason}
          checked={reason === selectedReason}
          onChange={() => onSelectReason(reason)}
        />
      );
    };

    const renderLabel = reason => {
      return (
        <Label key={`label-reason-${reason}`} htmlFor={`reason-${reason}`}>
          {translate(`refund.reasons.${reason}`)}
        </Label>
      );
    };

    return (
      <RefundDialog data-testid="refund-form">
        <Title>{translate('refund.title', [formatCurrency(price)])}</Title>
        <Paragraph>
          {hasCreditsCapability ? translate('refund.intro_credits') : translate('refund.intro')}
        </Paragraph>
        <form name="refund-form">
          {reasons.map(reason => [renderInput(reason), renderLabel(reason)])}
        </form>
        {selectedReason === 'other' ? (
          <OtherReason className="frm">
            <OtherTextArea
              className="inp inp-textarea inp-other"
              placeholder={translate('refund.other_placeholder')}
              onChange={onSetMessage}
              name="other"
              maxLength="255"
            />
          </OtherReason>
        ) : null}
        <SubmitButton
          data-testid="submit--button"
          color="razzmatazz"
          key="submit"
          disabled={!selectedReason || (selectedReason === 'other' && !message)}
          onClick={() => onRefund(selectedReason)}
          isLoading={status === STATUS_PENDING}
        >
          {hasCreditsCapability
            ? translate('refund.buttons.refund_credits')
            : translate('refund.buttons.refund', [formatCurrency(price)])}
        </SubmitButton>
        <StyledButton key="dismiss" color="transparent" onClick={onDismiss}>
          {translate('refund.buttons.cancel')}
        </StyledButton>
      </RefundDialog>
    );
  },
);

RefundReasons.propTypes = {
  price: number.isRequired,
  reasons: arrayOf(string).isRequired,
  selectedReason: string,
  message: string,
  onDismiss: func.isRequired,
  onSelectReason: func.isRequired,
  onSetMessage: func.isRequired,
  onRefund: func.isRequired,
  status: number,
  error: string,
};

RefundReasons.defaultProps = {
  selectedReason: null,
  status: STATUS_INITIAL,
  error: undefined,
  message: null,
};

export default RefundReasons;
