// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".refundDialog-1-4tI{text-align:left}.title-33ynS{font-family:\"GT-Walsheim\",sans-serif}.paragraph-2Zg06{font-family:\"Inter\",sans-serif}.button-jJ-Up{width:100%;font-weight:500}.button-jJ-Up:not(:last-child){margin-bottom:10px}.submitButton-1mPJR:disabled{filter:opacity(50%)}.label-1__pf{font-family:\"Inter\",sans-serif;display:block;padding-left:30px;cursor:pointer;margin:10px 0 15px;transition:max-height 0.2s, margin 0.2s;max-height:100px}.label-1__pf:before{content:'';display:inline-block;width:20px;height:20px;margin:-2px 0 0 -30px;position:absolute;background:#eaeaea;box-shadow:inset 0 1px 1px rgba(0,0,0,0.3);border-radius:50%}.otherReason-aoAer{display:flex;flex-direction:column}.otherTextArea-2s38O{width:100%}.input-OwCY5{display:none}.input-OwCY5:checked+.label-1__pf:before{background:#ff1060;box-shadow:0 1px 0 shade(#ff1060, 40%)}\n", ""]);
// Exports
exports.locals = {
	"refundDialog": "refundDialog-1-4tI",
	"title": "title-33ynS",
	"paragraph": "paragraph-2Zg06",
	"button": "button-jJ-Up",
	"submitButton": "submitButton-1mPJR",
	"label": "label-1__pf",
	"otherReason": "otherReason-aoAer",
	"otherTextArea": "otherTextArea-2s38O",
	"input": "input-OwCY5"
};
module.exports = exports;
